<template>
	<div></div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: 'IndexView',
	data() {
		return {
			loggedInUserData: null,
			startPromiseResolve: null
		}
	},
	async mounted() {
		let p = new Promise((resolve, reject) => {
			this.startPromiseResolve = resolve;
		});

		await p;

		if('ios' === window.platform || 'android' === window.platform && this.$store.state.loggedInUserToken) {
			this.$router.replace('/start-flight');
		}
		else {
			this.$router.replace('/map');
		}
	},
	computed: {
		...mapGetters([
			'getLoggedInUserData'
		])
	},
	watch: {
		getLoggedInUserData(loggedInUserData) {
			this.loggedInUserData = loggedInUserData;
			this.startPromiseResolve();
			this.startPromiseResolve = null;
		}
	},
}
</script>

<style>
.btn-dark.btn-main {
	word-break: keep-all;
	text-align: left;
	padding: 1rem 1rem;
	background-color: #0086FF;
	border: none;
	border-radius: 8px;
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.btn-dark.btn-main:hover,
.btn-dark.btn-main:active {
	background-color: #4752cc !important;
}

@media (min-width: 768px) {
	.btn-dark.btn-main {
		text-align: center;
		padding: 1rem 3rem;
	}
}

.footer-inner {
	border-top: 1px solid #eeeeee;
	padding-top: 10px;
	margin-bottom: 10px;
}
</style>
